import React, { Fragment } from 'react'

// Components
import SEO from '@components/SEO'

// Layouts
import MainLayout from '@layouts/MainLayout'

// Utils
import { useSiteMetadata } from '@utils/useSiteMetadata'

const About = () => {
  const {
    socialMedias: { facebookUrl, instagramUrl, linkedinUrl, githubUrl, codepenUrl, behanceUrl }
  } = useSiteMetadata()

  return (
    <Fragment>
      <SEO
        title='Hakkında'
        description={`Merhabalar ben Yasin Softaoğlu. 25 yaşındayım. İstanbul'da yaşıyorum. Yazılım mühendisiyim. 7 yıldır frontend
        development ile ilgileniyorum. Genellikle sektörü takip etmeye ve güncel teknolojiler kullanmaya çalışıyorum.`}
        keywords='Yasin Softaoğlu, Hakkında, About'
      />
      <MainLayout>
        <h1 className='title'>Hakkında</h1>

        <p>
          Merhabalar ben Yasin Softaoğlu. 25 yaşındayım. İstanbul'da yaşıyorum. Yazılım mühendisiyim. 7 yıldır frontend
          development ile ilgileniyorum. Genellikle sektörü takip etmeye ve güncel teknolojiler kullanmaya çalışıyorum. Beni daha
          yakından tanımak için aşağıdaki sosyal medya hesaplarıma göz atabilirsiniz.
        </p>

        <p>Sosyal medya hesaplarım:</p>

        <ul>
          <li>
            <a target='_blank' rel='noopener noreferrer' href={facebookUrl}>
              Facebook
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer' href={instagramUrl}>
              Instagram
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer' href={linkedinUrl}>
              Linkedin
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer' href={githubUrl}>
              Github
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer' href={codepenUrl}>
              Codepen
            </a>
          </li>
          <li>
            <a target='_blank' rel='noopener noreferrer' href={behanceUrl}>
              Behance
            </a>
          </li>
        </ul>
      </MainLayout>
    </Fragment>
  )
}

export default About
